import { Navigate } from 'react-router-dom'

import { useAuth } from '../contexts/AuthContext'

function PublicRoute({ children }) {
  const { isAuthorized } = useAuth()
  
  return isAuthorized() ? <Navigate to='/' /> : children
}

export default PublicRoute 