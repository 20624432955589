let environment = {
  apiUrl: process.env.PUBLIC_URL
}

async function loadEnvironment() {
  const response = await fetch(`${process.env.PUBLIC_URL}/environment.json`)
  const json = await response.json()
  environment = {
    ...environment,
    ...json
  }
}

export { environment, loadEnvironment }