import { createContext, useContext, useEffect, useState } from 'react'
import { signInWithPopup, signOut } from 'firebase/auth'

import { auth, googleProvider } from '../google/firebase'
import { environment } from '../helpers/environment'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [googleUser, setGoogleUser] = useState(null)
  const [profile, setProfile] = useState(null)
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState(null)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(_user => {
      setGoogleUser(_user)
      setLoading(false)
    })
    return unsubscribe
  }, [])

  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider)

      const idToken = await result.user.getIdToken()
      const res = await fetch(`${environment.apiUrl}/api/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: idToken }),
      })

      const { profile: _profile, token: _token } = (await res.json()) || { }

      if (!_token) {
        await signOut(auth)
        throw new Error('Email not authorized')
      }
      else {
        setProfile(_profile)
        setToken(_token)
        return _profile
      }
    } catch (error) {
      throw error
    }
  }

  const logout = () => {
    setProfile(null)
    setToken(null)
    return signOut(auth)
  }

  const isAuthorized = () => {
    return !!profile
  }

  const value = {
    googleUser,
    profile,
    token,
    signInWithGoogle,
    logout,
    isAuthorized
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
} 