import { Navigate } from 'react-router-dom'

import { useAuth } from '../contexts/AuthContext'

function PrivateRoute({ children }) {
  const { isAuthorized } = useAuth()
  
  return isAuthorized() ? children : <Navigate to='/login' />
}

export default PrivateRoute