import { lazy, Suspense, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import PublicRoute from './components/PublicRoute'
import PrivateRoute from './components/PrivateRoute'
import { loadEnvironment } from './helpers/environment'

const Login = lazy(() => import('./components/login/Login'))
const Home = lazy(() => import('./components/home/Home'))
const Upload = lazy(() => import('./components/upload/Upload'))

function App() {

  useEffect(() => {
    loadEnvironment()
  }, [])

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path='/login'
            element={<PublicRoute><Login /></PublicRoute>}
          />
          <Route
            path='/'
            element={<PrivateRoute><Home /></PrivateRoute>}
          />
          <Route
            path='/upload'
            element={<PrivateRoute><Upload /></PrivateRoute>}
          />
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App
