import { initializeApp } from 'firebase/app'
import { getAuth, GoogleAuthProvider } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyD9DpTu685iuzdm8R74dJ1nwziPM2ehz0E',
  authDomain: 'clothes-miduhouse.firebaseapp.com',
  projectId: 'clothes-miduhouse',
  storageBucket: 'clothes-miduhouse.firebasestorage.app',
  messagingSenderId: '1082634881602',
  appId: '1:1082634881602:web:27e8f5d819fe322e9f3bdb',
  measurementId: 'G-3ZY437YR0K'
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const googleProvider = new GoogleAuthProvider() 